





















import { Component, Prop, Vue } from 'vue-property-decorator';
import Page from '@/components/Page.vue';
import CampaignInformationCard from '../components/CampaignInformationCard.vue';
import CampaignStatsCard from '../components/CampaignStatsCard.vue';
import { CampaignService } from '../services/CampaignService';
import { Campaign } from '@/modules/campaign/domain/Campaign';
import { Creative } from '@/modules/creative/domain/Creative';

@Component({
    components: {
        Page,
        CampaignInformationCard,
        CampaignStatsCard,
    },
})
export default class CreateCampaign extends Vue {
    private isLoading: boolean = false;
    private hasSubmitted: boolean = false;

    private campaign: Campaign | Campaign = {
        name: '',
        advertiser: null,
        budget: 0,
        expectedDelivery: 0,
        expectedClicks: 0,
        creatives: [],
    };

    public rules =  {
        name: [
            { required: true, message: 'Le champs nom est requis!', trigger: 'blur' },
        ],
        advertiser: [
            { required: true, message: 'Le champs annonceur est requis!', trigger: 'blur' },
        ],
        startDate: [
            { required: true, message: 'Le champs date de début est requis!', trigger: 'blur' },
        ],
        endDate: [
            { required: true, message: 'Le champs date de fin est requis!', trigger: 'blur' },
        ],
    };

    private submit() {
        const form: any = this.$refs.campaignForm;
        form.validate((valid: boolean) => {
            if (valid) {
                this.createCampaign();
            } else {
                this.$notify.error({
                    title: 'Erreur',
                    message:
                        'Il ya des erreurs. Veuillez vérifier vos champs.',
                });
                return false;
            }
        });
    }

    private async createCampaign() {
        this.isLoading = true;
        try {
            // Le non null est vérifié plus haut.
            // @ts-ignore
            this.campaign.advertiserId = this.campaign.advertiser.id;
            const campaign: Campaign = await CampaignService.createCampaign(this.campaign, this.$store.getters['sessionStore/token']);
            this.$router.push('/view-campaign/' + campaign.id);
        } catch {
            this.$notify.error({
                title: 'Erreur',
                message:
                    'Erreur lors de la création de la campagne. Veuillez vérifier vos champs.',
            });
        }
        this.isLoading = false;
    }
}
